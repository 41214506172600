import * as React from 'react';
import { createContext, useEffect, useMemo } from 'react';

import type { PdfDocument, PdfJsLib, PdfPage } from 'bundles/cml/shared/components/asset/pdfViewer/types';

const DEFAULT_PDF_SCALE = 1.0;

export type PdfViewerContextType = {
  pdfViewerRef: React.RefObject<HTMLDivElement>;
  url: string;
  assetId: string;
  pdf: PdfDocument | null;
  currentPage: PdfPage | null;
  currentPageNumber: number;
  displayPageNumber: number;
  totalPages: number;
  zoom: number;
  pageStart: number;
  pageEnd?: number;
  setPdf: (pdf: PdfDocument | null) => void;
  setCurrentPage: (currentPage: PdfPage) => void;
  setCurrentPageNumber: (pageNumber: number) => void;
  setTotalPages: (numPages: number) => void;
  setZoom: (scale: number) => void;
  pdfJsLib?: PdfJsLib;
  onPdfLoad?: (doc: PdfDocument) => void;
};

export const PdfViewerContext = createContext<PdfViewerContextType | undefined>(undefined);

export const usePdfViewerContext = (): PdfViewerContextType => {
  const context = React.useContext(PdfViewerContext);
  if (!context) {
    throw new Error('PdfViewerContext has not been initialized.');
  }
  return context;
};

type PdfViewerContextProviderProps = {
  url: string;
  assetId: string;
  pageStart: number;
  pageEnd?: number;
  pdfViewerRef: React.RefObject<HTMLDivElement>;
  pdfJsLib?: PdfJsLib;
  onPdfLoad?: (doc: PdfDocument) => void;
};

export const PdfViewerContextProvider: React.LegacyFunctionComponentWithChildren<PdfViewerContextProviderProps> = ({
  url,
  assetId,
  pageStart,
  pageEnd,
  pdfViewerRef,
  pdfJsLib,
  onPdfLoad,
  children,
}) => {
  const [pdf, setPdf] = React.useState<PdfDocument | null>(null);
  const [currentPage, setCurrentPage] = React.useState<PdfPage | null>(null);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(pageStart || 1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [zoom, setZoom] = React.useState(DEFAULT_PDF_SCALE);

  const calculatedTotalPages = useMemo(() => {
    return pageEnd && pageStart ? Math.min(pageEnd - pageStart + 1, totalPages) : totalPages;
  }, [pageEnd, pageStart, totalPages]);

  const displayPageNum = useMemo(() => Math.max(1, currentPageNumber - pageStart + 1), [currentPageNumber, pageStart]);

  const contextValue = useMemo(
    () => ({
      pdfJsLib,
      pdfViewerRef,
      url,
      assetId,
      pdf,
      pageStart,
      pageEnd,
      currentPage,
      currentPageNumber:
        pageStart && pageEnd && (currentPageNumber < pageStart || currentPageNumber > pageEnd)
          ? pageStart
          : currentPageNumber,
      displayPageNumber: displayPageNum,
      totalPages: calculatedTotalPages,
      zoom,
      setPdf,
      setCurrentPage,
      setCurrentPageNumber,
      setTotalPages,
      setZoom,
      onPdfLoad,
    }),
    [
      calculatedTotalPages,
      currentPage,
      currentPageNumber,
      displayPageNum,
      onPdfLoad,
      pageStart,
      pageEnd,
      pdf,
      pdfJsLib,
      pdfViewerRef,
      url,
      assetId,
      zoom,
    ]
  );

  return <PdfViewerContext.Provider value={contextValue}> {children} </PdfViewerContext.Provider>;
};

import Fluxible from 'vendor/cnpm/fluxible.v0-4/index';

import AliceStore from 'bundles/alice/stores/AliceStore';
import routes from 'bundles/enterprise-home/routes';
import NaptimeStore from 'bundles/naptimejs/stores/NaptimeStore';
import ApplicationStore from 'bundles/ssr/stores/ApplicationStore';

import 'css!bundles/global-styles/global-element-styles';

const appEnv = new Fluxible({
  component: routes,
});

appEnv.registerStore(ApplicationStore);
appEnv.registerStore(NaptimeStore);
appEnv.registerStore(AliceStore);

export default appEnv;

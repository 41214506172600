/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { Grid, Hidden, IconButton, TextField, Typography2 } from '@coursera/cds-core';
import { ChevronNextIcon, ChevronPreviousIcon, MoreActionsIcon, ZoomInIcon, ZoomOutIcon } from '@coursera/cds-icons';

import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';
import { ToolbarMobileFloatingMenu } from 'bundles/cml/shared/components/asset/pdfViewer/ToolbarMobileFloatingMenu';
import { ToolbarRightSideActions } from 'bundles/cml/shared/components/asset/pdfViewer/ToolbarRightSideActions';

import _t from 'i18n!nls/cml';

const styles = {
  rightSideAtionsContainer: css`
    display: flex;

    @media only screen and (max-width: 600px) {
      display: none;
    }

    @media all and (display-mode: fullscreen) {
      display: flex;
    }
  `,
  mobileMoreMenuContainer: css`
    display: none;

    @media only screen and (max-width: 600px) {
      display: flex;
    }

    @media all and (display-mode: fullscreen) {
      display: none;
    }
  `,
  toolbar: css`
    padding: var(--cds-spacing-150) var(--cds-spacing-400);
    width: 100%;
    display: flex;
    background: white;
    box-shadow: 0 1px 3px var(--cds-color-neutral-stroke-primary-weak);

    @media (max-width: 608px) {
      padding-left: 0;
      padding-right: 0;
    }
  `,
  totalPagesNumber: css`
    margin-left: var(--cds-spacing-100);

    @media (max-width: 608px) {
      margin-left: 0;
    }
  `,
  // OVERRIDES FOR CDS INPUT
  textField: css`
    .cds-text-field-root {
      margin-top: 0;
    }

    .cds-input-input {
      width: 15px !important;
      height: 10px !important;
    }

    label {
      display: none;
    }
  `,
};

export const Toolbar = () => {
  const PDF_ZOOM_STEP = 0.25;
  const PDF_MIN_ZOOM = 0.25;
  const PDF_MAX_ZOOM = 3;

  const { totalPages, currentPageNumber, displayPageNumber, zoom, setZoom, setCurrentPageNumber, pageStart } =
    usePdfViewerContext();

  const [ref, setRef] = React.useState<HTMLButtonElement | null>(null);

  const [mobileFloatingMenuOpen, setMobileFloatingMenuOpen] = React.useState(false);

  const [pageInputVal, setPageInputVal] = React.useState<string>(displayPageNumber.toString());

  const handlePrevPage = () => {
    if (currentPageNumber > pageStart) {
      setCurrentPageNumber(currentPageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPageNumber < totalPages + pageStart - 1) {
      setCurrentPageNumber(currentPageNumber + 1);
    }
  };

  const handlePageNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageNumber = parseInt(event.target.value, 10);
    if (newPageNumber > totalPages) {
      setPageInputVal(totalPages.toString());
      setCurrentPageNumber(totalPages + pageStart - 1);
    } else if (newPageNumber <= 0) {
      setPageInputVal('1');
      setCurrentPageNumber(pageStart);
    } else if (isNaN(newPageNumber + pageStart - 1)) {
      setPageInputVal('');
    } else {
      setPageInputVal(newPageNumber.toString());
      setCurrentPageNumber(newPageNumber + pageStart - 1);
    }
  };

  const handleZoomIn = () => {
    setZoom(Math.min(PDF_MAX_ZOOM, zoom + PDF_ZOOM_STEP));
  };

  const handleZoomOut = () => {
    setZoom(Math.max(PDF_MIN_ZOOM, zoom - PDF_ZOOM_STEP));
  };

  const toggleFloatingMenu = () => {
    setMobileFloatingMenuOpen(!mobileFloatingMenuOpen);
  };

  const closeFloatingMenu = () => {
    setMobileFloatingMenuOpen(false);
  };

  useEffect(() => {
    setPageInputVal(displayPageNumber.toString());
  }, [displayPageNumber, totalPages]);

  return (
    <div css={styles.toolbar}>
      <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
        <Grid container alignItems="center" item xs spacing={4} wrap="nowrap">
          <Grid item>
            <IconButton
              icon={<ChevronPreviousIcon />}
              tooltip={_t('Previous page')}
              variant="ghost"
              onClick={handlePrevPage}
              disabled={displayPageNumber === 1}
              aria-label={
                displayPageNumber === 1
                  ? _t('Previous page')
                  : _t('Previous page: #{page} of #{totalPages}', {
                      page: displayPageNumber - 1,
                      totalPages,
                    })
              }
              data-testid="prevPageButton"
            />
          </Grid>
          <Grid item>
            <TextField
              css={styles.textField}
              type="number"
              inputProps={{ inputMode: 'numeric' }}
              value={pageInputVal}
              onChange={handlePageNumberChange}
              label={_t('Enter the page number you wish to jump to.')}
            />
          </Grid>
          <Grid item data-testid="pageCount">
            <Typography2 css={styles.totalPagesNumber} component="span">
              {' '}
              / {totalPages}
            </Typography2>
          </Grid>
          <Grid item>
            <IconButton
              icon={<ChevronNextIcon />}
              tooltip={_t('Next page')}
              variant="ghost"
              onClick={handleNextPage}
              disabled={displayPageNumber >= totalPages}
              aria-label={
                displayPageNumber >= totalPages
                  ? _t('Next page')
                  : _t('Next page: #{page} of #{totalPages}', {
                      page: displayPageNumber + 1,
                      totalPages,
                    })
              }
              data-testid="nextPageButton"
            />
          </Grid>
        </Grid>
        <Grid container item xs alignItems="center" justifyContent="center" spacing={4} wrap="nowrap">
          <Grid item>
            <IconButton
              icon={<ZoomOutIcon />}
              tooltip={_t('Zoom out')}
              variant="ghost"
              onClick={handleZoomOut}
              disabled={zoom <= PDF_MIN_ZOOM}
              aria-label={
                zoom <= PDF_MIN_ZOOM
                  ? _t('Zoom out')
                  : _t('Zoom out #{zoom}%', {
                      zoom: (zoom - PDF_ZOOM_STEP) * 100,
                    })
              }
              data-testid="zoomOutButton"
            />
          </Grid>
          <Grid item>
            <Hidden xsDown>
              <Typography2 component="span">{zoom * 100}%</Typography2>
            </Hidden>
          </Grid>
          <Grid item>
            <IconButton
              icon={<ZoomInIcon />}
              tooltip={_t('Zoom in')}
              variant="ghost"
              onClick={handleZoomIn}
              disabled={zoom >= PDF_MAX_ZOOM}
              aria-label={
                zoom >= PDF_MAX_ZOOM
                  ? _t('Zoom in')
                  : _t('Zoom in #{zoom}%', {
                      zoom: (zoom + PDF_ZOOM_STEP) * 100,
                    })
              }
              data-testid="zoomInButton"
            />
          </Grid>
        </Grid>
        <Grid container item xs justifyContent="flex-end" css={styles.rightSideAtionsContainer}>
          <ToolbarRightSideActions />
        </Grid>
        <Grid container item xs justifyContent="flex-end" css={styles.mobileMoreMenuContainer}>
          <IconButton
            icon={<MoreActionsIcon />}
            tooltip={_t('More options')}
            ref={setRef}
            variant="ghost"
            aria-label={_t('More options')}
            onClick={toggleFloatingMenu}
          />

          {mobileFloatingMenuOpen && <ToolbarMobileFloatingMenu anchorEl={ref} onClose={closeFloatingMenu} />}
        </Grid>
      </Grid>
    </div>
  );
};

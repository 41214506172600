import type React from 'react';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { CloseIcon } from '@coursera/cds-icons';

import type { ButtonProps } from '@core/Button';
import IconButtonBase from '@core/IconButton/IconButtonBase';
import i18nMessages from '@core/notifications/i18n';

export type DismissActionProps = Partial<
  Omit<ButtonProps, 'icon' | 'size' | 'variant'>
>;

const DismissAction = (
  props: DismissActionProps
): React.ReactElement<DismissActionProps> => {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);
  return (
    <IconButtonBase
      aria-label={stringFormatter.format('dismiss')}
      size="small"
      variant="ghost"
      {...props}
    >
      <CloseIcon />
    </IconButtonBase>
  );
};

export default DismissAction;

/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { Theme } from '@coursera/cds-core';

import LegacyWidgetPreviewFrame from 'bundles/author-widget/components/preview/LegacyWidgetPreviewFrame';
import { isPluginUpdatedUiEnabled } from 'bundles/authoring/featureFlags';
import type { PluginRpcMessage, SetHeightRpcMessage } from 'bundles/widget-simulator/types/RpcMessages';
import Simulator from 'bundles/widget-simulator/utils/Simulator';
import { WidgetInPlaceModal, inPlaceModalStyles } from 'bundles/widget/components/cds/WidgetInPlaceModal';
import type { WidgetInPlaceModalRef } from 'bundles/widget/components/cds/WidgetInPlaceModal';
import { WidgetExpandButton, WidgetInPlaceModalHeader } from 'bundles/widget/components/cds/WidgetInPlaceModalHeader';
import { initiateChild, listenToChildMessages } from 'bundles/widget/lib/coursera-connect/coursera-connect-parent';
import type { WidgetSession } from 'bundles/widget/types/WidgetSession';
import { isDialogSupported } from 'bundles/widget/utils/isDialogSupported';

import _t from 'i18n!nls/author-widget';

// keeping this in place becuase there is a body-level selector...
import 'css!./__styles__/WidgetPreviewFrame';

// TODO: DRY this up (with the simulator's Frame, etc).
const rpcActionTypes = [
  'LOAD_WIDGET_ERROR',
  'GET_SESSION_CONFIGURATION',
  'SET_STORED_VALUES',
  'GET_STORED_VALUES',
  'SET_ANSWER',
  'SET_HEIGHT',
  'SET_COMPLETE',
];

const styles = {
  wrapper: css`
    height: 100%;
    margin-bottom: 10px;

    .widget-container {
      .expander {
        height: 44px;
        width: 100px;
        margin-top: 10px;
        background-color: #fff;
        border: 1px solid #ccc;
      }
    }
  `,

  expandRow: css`
    margin-top: var(--cds-spacing-200);
  `,
};

export type Session = WidgetSession;

type PropsToComponent = {
  widgetContentTitle?: string;
  sessionId?: string;
  session: WidgetSession | undefined;
  showPopupButton: boolean;
  showWidgetFooter?: (show: boolean) => void;
  renderCustomContainer?: (onExpand: () => void) => React.ReactNode;
  headerCss?: Interpolation<Theme>;
};

export const FALLBACK_DEFAULT_HEIGHT = '500px';

/*
There are several ways the height of the iframe is set, at different points in time. Below is the order of application:

1) SET_HEIGHT rpc message
- I believe this happens after the plugin content starts loading (ie whenever the plugin content runs the JS for this)
- sent by the plugin contents via coursera-connect.js script embedded in iframe contents
- sets the specifiedHeight state in here, which then sets the height attribute on the iframe and removes the `default-height` classname on the iframe

2) Iframe content height (<-- doesn't currently work but it looks liek this is the next intended order or priority)
- runs onLoad of the iframe
- smartHeight function is called which tries to grab the height of the content within the iframe. 
- if it succeeds, write directly to the iframe 'height' attribute
- it looks like the default-height classname is still applied so there is a conflict between the ifram eheight attribute and height css property in this scenario???
- a little unclear what the intent is in setting both??? From manual testing,
it appears that the classname css property overrides the height property.

3) DEFAULT_HEIGHT
- if no height is provided, use the default height settings
- the iframe height attribute is set to 500px while the css defaultHeight classnaem is also set which sets the height to calc(90vh - 50px)
- a little unclear what the intent is in setting both??? From manual testing,
it appears that the classname css property overrides the height property.

 */
const WidgetPreviewFrame = ({
  widgetContentTitle,
  sessionId,
  session,
  showWidgetFooter,
  renderCustomContainer,
  showPopupButton,
  headerCss,
}: PropsToComponent) => {
  const [specifiedHeight, setSpecifiedHeight] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(false);
  const [iframeContentLoaded, setIframeContentLoaded] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const WidgetModalRef = useRef<WidgetInPlaceModalRef | null>(null);

  const removeListenerHandleRef = useRef<(() => void) | undefined>(undefined);

  const simulatorRef = useRef<Simulator | undefined>(undefined);

  const frameRef = useRef<HTMLIFrameElement | null>(null);

  const onExpandToggle = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    // TODO @sgogia
    // slightly hacky way of ensuring body (higher in hierarchy) can't be scrolled when widget expanded

    if (!expanded) {
      document?.querySelector('body')?.classList?.add('noscroll');
    } else {
      document?.querySelector('body')?.classList?.remove('noscroll');
    }
    const shouldExpand = !expanded;
    setExpanded(!expanded);
    if (shouldExpand) {
      WidgetModalRef.current?.showWidgetInModal();
    } else if (!shouldExpand) {
      WidgetModalRef.current?.showWidgetInPlace();
    }
  }, [expanded]);

  const onReceiveMessage = useCallback<(...args: $TSFixMe[]) => $TSFixMe>((message: PluginRpcMessage) => {
    return simulatorRef.current?.handleMessage(message);

    // Execution should never reach this point.
    return Promise.resolve();
  }, []);

  const sendMessageToIFrame = useCallback<(...args: $TSFixMe[]) => $TSFixMe>((message: $TSFixMe, src: string) => {
    frameRef.current?.contentWindow?.postMessage(message, src);
  }, []);

  const trySmartHeightDefault = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    /* This doesn't seem to actually work because the iframe source is at a different
    domain than coursera.org and fails due to cross origin security issues.
    I dont think there'd be many plugins pointing to the coursera.org domain but
    this is left in case there are.
    */

    if (!!frameRef.current && !specifiedHeight) {
      try {
        const contentsHeight = frameRef.current.contentWindow?.document?.body?.scrollHeight;
        if (contentsHeight && contentsHeight > 0) {
          frameRef.current.height = contentsHeight + 'px';
        }
      } catch (e) {
        // if we can't get the height of the contents, we'll keep our default height
      }
    }
  }, [specifiedHeight]);

  const onContentLoad = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    trySmartHeightDefault();
    showWidgetFooter?.(true);
    setIframeContentLoaded(true);
  }, [showWidgetFooter, trySmartHeightDefault]);

  const setupSimulator = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    // TODO: Move this to another file.
    const simulator = new Simulator();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('LOAD_WIDGET_ERROR', (_message) => {
      return Promise.resolve();
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('GET_SESSION_CONFIGURATION', (_message) => {
      return Promise.resolve(session?.configuration);
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('SET_STORED_VALUES', (_message) => {
      // Does nothing in the preview tool for now. Returns an empty array representing no stored value names.
      return Promise.resolve([]);
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('GET_STORED_VALUES', (_message) => {
      // Does nothing in the preview tool for now. Returns an empty object representing no returned values.
      return Promise.resolve({});
    });

    simulator.addHandler('SET_HEIGHT', (message) => {
      function isHeightMessage(messageToCheck: PluginRpcMessage): messageToCheck is SetHeightRpcMessage {
        return (messageToCheck as SetHeightRpcMessage).body?.height !== undefined;
      }

      if (isHeightMessage(message)) {
        const { height } = message.body;
        setSpecifiedHeight(height);
      }
      return Promise.resolve();
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('SET_ANSWER', (_message) => {
      // Does nothing in the preview tool for now.
      return Promise.resolve();
    });

    // REMOTE
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('SET_COMPLETE', (_message) => {
      // Does nothing in the preview tool for now.
      return Promise.resolve();
    });

    simulatorRef.current = simulator;
  }, [session?.configuration]);

  const renderExpandContainer = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    const showExpansionButton = !expanded && showPopupButton;
    if (!iframeContentLoaded || !showExpansionButton) {
      return null;
    }

    if (renderCustomContainer) {
      return renderCustomContainer(onExpandToggle);
    }

    return (
      <div css={styles.expandRow}>
        <WidgetExpandButton
          onClick={onExpandToggle}
          label={expanded ? _t('Close expanded preview.') : _t('Expand preview.')}
        />
      </div>
    );
  }, [expanded, iframeContentLoaded, onExpandToggle, renderCustomContainer, showPopupButton]);

  const getModalTitle = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (sessionArg?: WidgetSession, widgetContentTitleArg?: string): string => {
      const defaultUrlIframeTitles = ['External Webpage (iframe)', 'YouTube'];
      if (widgetContentTitleArg) {
        return widgetContentTitleArg;
      }
      if (sessionArg?.iframeTitle) {
        return defaultUrlIframeTitles.includes(sessionArg.iframeTitle) ? '' : sessionArg.iframeTitle;
      }
      return '';
    },
    []
  );

  useEffect(() => {
    if (isMounted) {
      return;
    }
    // no need to make connections if there are no rpc actions specified.
    if (!session?.rpcActionTypes?.length) {
      return;
    }

    // TODO: Figure out what these values should be.
    const iFrameSrc = '*';
    const widgetId = sessionId || 'widgetId123';

    setupSimulator();

    initiateChild(sendMessageToIFrame, widgetId, iFrameSrc).then(() => {
      removeListenerHandleRef.current = listenToChildMessages(
        widgetId,
        rpcActionTypes,
        onReceiveMessage,
        sendMessageToIFrame,
        iFrameSrc
      );
    });
    setIsMounted(true);
  }, [isMounted, onReceiveMessage, sendMessageToIFrame, session?.rpcActionTypes?.length, sessionId, setupSimulator]);

  useEffect(() => {
    return () => {
      removeListenerHandleRef.current?.();
    };
  }, []);

  const src = session?.src;
  const sandbox = session?.sandbox;

  const height = specifiedHeight || FALLBACK_DEFAULT_HEIGHT;

  const width = '100%';

  const modalTitle = getModalTitle(session, widgetContentTitle);
  return (
    <React.Fragment>
      <WidgetInPlaceModal
        expanded={expanded}
        modalStyles={{ modal: styles.wrapper }}
        ref={WidgetModalRef}
        header={
          <WidgetInPlaceModalHeader widgetContentTitle={modalTitle} onExpandToggle={onExpandToggle} css={headerCss} />
        }
      >
        <iframe
          title={
            modalTitle ? _t('Plugin: #{title}', { title: getModalTitle(session, widgetContentTitle) }) : _t('Plugin')
          }
          src={src}
          height={height}
          width={width}
          sandbox={sandbox}
          ref={frameRef}
          onLoad={onContentLoad}
          allow="camera"
          css={expanded ? inPlaceModalStyles.expandedWidgetIframe : { height }}
        />
      </WidgetInPlaceModal>
      {renderExpandContainer()}
    </React.Fragment>
  );
};

const WidgetPreviewFrameWithFallback = (props: PropsToComponent) => {
  if (!isDialogSupported()) {
    return <LegacyWidgetPreviewFrame {...props} />;
  }
  return isPluginUpdatedUiEnabled() ? <WidgetPreviewFrame {...props} /> : <LegacyWidgetPreviewFrame {...props} />;
};

export default WidgetPreviewFrameWithFallback;

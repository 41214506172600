export const BLOCK_TYPES = {
  HEADING: 'heading',
  LINK: 'link',
  BULLET_LIST: 'bullet-list',
  NUMBER_LIST: 'number-list',
  LIST_ITEM: 'list-item',
  LEGACY_AUDIO: 'audio',
  IMAGE: 'image',
  IMAGE_UPLOADER: 'image-uploader',
  ASSET: 'asset',
  CODE: 'code',
  TABLE: 'table',
  TABLE_ROW: 'table-row',
  TABLE_CELL: 'table-cell',
  TEXT: 'text',
  PERSONALIZATION_TAG: 'ptag',
  FILLABLE_BLANK: 'fillable-blank',
  MATH_BLOCK: 'math-block',
  MATH_INLINE: 'math-inline',
  WIDGET: 'widget',
  AI_ELEMENT: 'ai-element',
  AI_CURSOR: 'ai-cursor',
} as const;

export const MARKS = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  MONOSPACE: 'monospace',
  SUPERSCRIPT: 'superscript',
  SUBSCRIPT: 'subscript',
  ADDED: 'added',
  REMOVED: 'removed',
} as const;

export const DIFF_TYPES = {
  ADD: 'add',
  REMOVE: 'remove',
  CHANGE: 'change',
} as const;

export type DIFF_VALUES = (typeof DIFF_TYPES)[keyof typeof DIFF_TYPES];
export type BLOCK_VALUES = (typeof BLOCK_TYPES)[keyof typeof BLOCK_TYPES] | DIFF_VALUES;
export type MARK_VALUES = (typeof MARKS)[keyof typeof MARKS];

const BACKSPACE_CODEPOINT = '\u0008'; // PARTNER-18163
const ZERO_WIDTH_SPACE_CODEPOINT = '\u200B'; // COREAUTH-3994
const ZERO_WIDTH_NON_JOINER_CODEPOINT = '\u200C';
const ZERO_WIDTH_JOINER_CODEPOINT = '\u200D';
const ZERO_WIDTH_NON_BREAKING_SPACE_CODEPOINT = '\uFEFF';

const BLACKLIST_CODEPOINTS = [
  BACKSPACE_CODEPOINT,
  ZERO_WIDTH_SPACE_CODEPOINT,
  ZERO_WIDTH_NON_JOINER_CODEPOINT,
  ZERO_WIDTH_JOINER_CODEPOINT,
  ZERO_WIDTH_NON_BREAKING_SPACE_CODEPOINT,
];

export const BLACKLIST_TEXT_REGEX = new RegExp(`[${BLACKLIST_CODEPOINTS.join('')}]`, 'g');

export const AI_TOOL = {
  SUMMARIZE: 'SUMMARIZE',
  REPHRASE: 'REPHRASE',
  CONTINUE: 'CONTINUE',
  PROMPT: 'PROMPT',
  LIST: 'LIST',
  TABLE: 'TABLE',
} as const;

export type AI_TOOLS = (typeof AI_TOOL)[keyof typeof AI_TOOL];

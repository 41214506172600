import * as React from 'react';
import { createContext, useContext, useMemo } from 'react';

import type AuthoringCourseBranch from 'bundles/author-branches/models/AuthoringCourseBranch';
import type { AuthoringCourse } from 'bundles/author-common/models/AuthoringCourse';
import type { CourseCatalogType } from 'bundles/author-course/utils/types';
import type {
  AuthoringCourseContextInformation,
  GroupContextDefinition,
} from 'bundles/authoring/common/types/authoringCourseContexts';
import { getContextId } from 'bundles/authoring/common/utils/ItemSharingUtils';
import type { ReplaceCustomContent as ReplaceCustomContentType } from 'bundles/custom-labels/types/CustomLabels';

type ProviderProps = {
  courseContext: AuthoringCourseContextInformation;
  course: AuthoringCourse;
  branch: AuthoringCourseBranch;
  courseCatalogType: CourseCatalogType;
  replaceCustomContent: ReplaceCustomContentType;
};

export type ContextProps = {
  courseContext: AuthoringCourseContextInformation;
  contextBranchName: string;
  contextId: string;
  partnerIds: number[];
  branch: AuthoringCourseBranch;
  courseCatalogType: CourseCatalogType;
  replaceCustomContent: ReplaceCustomContentType;
};

export const CourseContextContext = createContext<ContextProps | undefined>(undefined);

export const useCourseContext = () => {
  const context = useContext(CourseContextContext);
  if (context) {
    return context;
  } else {
    // some components in bundles/cml access this from the learner side, so this hack allows thos usages to work until a better solution is in place
    return {} as ContextProps;
  }
};

export const CourseContextContextProvider: React.LegacyFunctionComponentWithChildren<ProviderProps> = ({
  courseContext,
  branch,
  course,
  courseCatalogType,
  replaceCustomContent,
  children,
}) => {
  const value = useMemo(
    () => ({
      courseContext,
      contextBranchName: (courseContext as GroupContextDefinition)?.branchName || courseContext?.name || '',
      branch,
      partnerIds: course.partnerIds,
      contextId: getContextId(courseContext),
      courseCatalogType,
      replaceCustomContent,
    }),
    [courseContext, branch, course.partnerIds, courseCatalogType, replaceCustomContent]
  );

  return <CourseContextContext.Provider value={value}>{children}</CourseContextContext.Provider>;
};

export default { CourseContextContextProvider, useCourseContext };

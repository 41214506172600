/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Children, type ReactNode } from 'react';

import type { RenderElementProps } from 'slate-react';

import logger from 'js/app/loggerSingleton';

import TableRenderer from 'bundles/cml/shared/components/table/Table';
import type { TableElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  root: css`
    overflow: auto;
  `,
};

const splitHeader = (table: TableElement, children: ReactNode | ReactNode[]) => {
  if (table.headless) {
    return { header: null, rows: children };
  }

  const renderRows = Children.toArray(children)[0];
  if (React.isValidElement(renderRows)) {
    return {
      header: React.cloneElement(renderRows, {}, [table.children[0]]),
      rows: React.cloneElement(renderRows, {}, table.children.slice(1)),
    };
  }

  logger.error('CMLViewer: table render failed');
  return { header: null, rows: children };
};

const Table: React.FC<RenderElementProps> = ({ element, attributes, children }) => {
  const tableElement = element as TableElement;
  const { header, rows } = splitHeader(tableElement, children);

  return (
    <TableRenderer element={element} attributes={attributes} header={header} rows={rows} css={styles.root}>
      {null}
    </TableRenderer>
  );
};

export default Table;

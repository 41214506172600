import type { IconProps } from '@coursera/cds-common';
import {
  SuccessIcon,
  WarningIcon,
  ErrorIcon,
  InformativeIcon,
} from '@coursera/cds-icons';

import type { NotificationSeverity } from '@core/notifications/types';

import { classes } from './inlineNotificationCss';

const InlineSeverityIcon = ({
  severity,
}: {
  severity: NotificationSeverity;
}) => {
  const commonProps: IconProps = {
    size: 'medium',
    display: 'block',
    className: classes.inlineSeverityIcon,
  };

  switch (severity) {
    case 'success':
      return <SuccessIcon color="success" {...commonProps} />;
    case 'warning':
      return <WarningIcon color="warning" {...commonProps} />;
    case 'error':
      return <ErrorIcon color="error" {...commonProps} />;
    case 'information':
    default:
      return <InformativeIcon color="interactive" {...commonProps} />;
  }
};

export default InlineSeverityIcon;

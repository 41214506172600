import * as React from 'react';

import config from 'js/app/config';

import { Grid, IconButton } from '@coursera/cds-core';
import { DownloadIcon, FullscreenExitIcon, FullscreenIcon } from '@coursera/cds-icons';

import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';

import _t from 'i18n!nls/cml';

export const ToolbarRightSideActions: React.FC = () => {
  const { pdfViewerRef, pageStart, pageEnd, assetId } = usePdfViewerContext();

  const handleFullscreen = () => {
    const isFullscreen = !!document.fullscreenElement;

    if (!isFullscreen) {
      pdfViewerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleDownload = () => {
    const downloadUrl = new URL(`api/rest/v1/asset/download/pdf/${assetId}`, config.url.base);
    if (pageStart && pageEnd) {
      downloadUrl.searchParams.append('pageStart', pageStart.toString());
      downloadUrl.searchParams.append('pageEnd', pageEnd.toString());
    }
    // this endpoint returns the truncated file directly as the response
    window.open(downloadUrl);
  };
  return (
    <Grid container item justifyContent="flex-end" alignItems="center" xs>
      <Grid item>
        <IconButton
          icon={<DownloadIcon />}
          tooltip={_t('Download')}
          variant="ghost"
          onClick={handleDownload}
          aria-label={_t('Download the provided PDF file')}
        />
      </Grid>
      <Grid item>
        <IconButton
          icon={document.fullscreenElement ? <FullscreenExitIcon /> : <FullscreenIcon />}
          tooltip={_t('Fullscreen')}
          variant="ghost"
          onClick={handleFullscreen}
          aria-label={_t('Fullscreen mode')}
          data-testid="fullscreenButton"
        />
      </Grid>
    </Grid>
  );
};

import type React from 'react';

import type { IconProps } from '@coursera/cds-common';
import {
  ErrorIcon,
  InformativeIcon,
  SuccessIcon,
  WarningIcon,
} from '@coursera/cds-icons';

import type { severities } from '@core/notifications/LocalNotification/getLocalNotificationCss';

export type SeverityIconProps = {
  /**
   * Notification severity type
   */
  severity: keyof typeof severities;
};

const SeverityIcon = (
  props: SeverityIconProps
): React.ReactElement<SeverityIconProps> => {
  const { severity } = props;
  const commonProps: IconProps = {
    size: 'large',
    display: 'block',
  };

  switch (severity) {
    case 'success':
      return <SuccessIcon color="success" {...commonProps} />;
    case 'warning':
      return <WarningIcon color="warning" {...commonProps} />;
    case 'error':
      return <ErrorIcon color="error" {...commonProps} />;
    case 'information':
    default:
      return <InformativeIcon color="interactive" {...commonProps} />;
  }
};

export default SeverityIcon;
